






































































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";

@Component
export default class GeoEmissionsImport extends Vue {
  private emissionsFile: any = null;
  private emissionsFileUpdate: any = null;
  private populationFile: any = null;
  private grpFile: any = null;

  private emissionsFileChanged(event) {
    this.emissionsFile = event.target.files[0];
  }

  private async importEmissions() {
    let formData = new FormData();
    formData.append("file", this.emissionsFile);
    await adminService.importGeoAreaEmissions(formData);
  }

  private emissionsFileUpdateChanged(event) {
    this.emissionsFileUpdate = event.target.files[0];
  }

  private async importEmissionsUpdate() {
    let formData = new FormData();
    formData.append("file", this.emissionsFileUpdate);
    await adminService.importGeoAreaEmissionsUpdate(formData);
  }

  private populationFileChanged(event) {
    this.populationFile = event.target.files[0];
  }

  private async importPopulation() {
    let formData = new FormData();
    formData.append("file", this.populationFile);
    await adminService.importGeoAreaPopulation(formData);
  }

  private grpFileChanged(event) {
    this.grpFile = event.target.files[0];
  }

  private async importGrp() {
    let formData = new FormData();
    formData.append("file", this.grpFile);
    await adminService.importGeoAreaGrp(formData);
  }
}
